import React from "react";
import "./Footer.scss";
import companyLogo from "../media/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Column 1: Company Info */}
        <div className="footer-column footer-company">
          <img src={companyLogo} alt="Company Logo" className="footer-logo" />
          <h3>Anzeg Institute</h3>
          <p>info@anzeginstitute.org</p>
        </div>

        {/* Column 2: Contact Info */}
        <div className="footer-column footer-contact">
          <h3>Contact Us</h3>
          <p>Sydney, NSW, Australia</p>
          <p>Auckland, New Zealand</p>
          <p>Cairo, Egypt</p>
          <p>Phone: +210 258 96648</p>
          <p>Phone: +022 677 2013</p>
        </div>

        {/* Column 3: Developer Info */}
        <div className="footer-column footer-developed">
          <h3>Developed By</h3>
          <p>Email: malagroudi@gmail.com</p>
          <p>Phone: +20 106 623 4705</p>
        </div>

        {/* Column 4: Social Media */}
        <div className="footer-column footer-social">
          <h3>Follow Us</h3>
          <div className="social-links">
            <a
              href="https://www.facebook.com/share/19PzDk2nEE/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/mo198086/profilecard/?igsh=MXVoYm9zbG45eGFidw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/anzeg-institute-351627339?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Anzeg Institute. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
