import React from "react";
import "./PastEvents.scss";
import NavBar from "./NavBar";
import Footer from "./Footer";

// Import all images
import img57357 from "../media/events/past/57357.jpg";
import imgAFL1 from "../media/events/past/AFL-1.jpg";
import imgAFL2 from "../media/events/past/AFL-2.jpg";
import imgAFL3 from "../media/events/past/AFL-3.jpg";
import imgAFL4 from "../media/events/past/AFL-4.jpg";
import imgAFL5 from "../media/events/past/AFL-5.jpg";
import imgAFL6 from "../media/events/past/AFL-6.jpg";
import imgAFL7 from "../media/events/past/AFL-7.jpg";
import imgAmoun from "../media/events/past/amoun.jpg";
import imgAttending from "../media/events/past/attending.jpg";
import imgAudience from "../media/events/past/audience.jpg";
import imgAusArtAuditorium from "../media/events/past/aus-art-auditorium.jpg";
import imgAusArtFest from "../media/events/past/aus-art-fest.jpg";
import imgAusEgCollab from "../media/events/past/aus-eg-collab.jpg";
import imgAusEgTheatre from "../media/events/past/aus-eg-theatre.jpg";
import imgAusFilm from "../media/events/past/aus-film.jpg";
import imgBasira from "../media/events/past/basira.jpg";
import imgBasira2 from "../media/events/past/basira-2.jpg";
import imgCairoJazzFest from "../media/events/past/cairo-jazz-fest.jpg";
import imgCairoJazz from "../media/events/past/cairo-jazz.jpg";
import imgCanadianBusiness from "../media/events/past/canadian-business.jpg";
import imgCU from "../media/events/past/cu.jpg";
import imgCurrentAusAmb from "../media/events/past/current-aus-amb.jpg";
import imgEdith from "../media/events/past/edith.jpg";
import imgEgArtAus from "../media/events/past/eg-art-aus.jpg";
import imgEgMediaAus from "../media/events/past/eg-media-aus.jpg";
import imgEgMediaInd from "../media/events/past/eg-media-ind.jpg";
import imgAusDay from "../media/events/past/aus-day.jpg";
import imgEgyMedia from "../media/events/past/egy-media.jpg";
import imgEmergingTalent from "../media/events/past/emerging-talent.jpg";
import imgExtraCur from "../media/events/past/extra-cur.jpg";
import imgFarewellAus from "../media/events/past/farewell-aus.jpg";
import imgGallipoli from "../media/events/past/gallipoli.jpg";
import imgGallipoli2 from "../media/events/past/gallipoli-2.jpg";
import imgGallipoli3 from "../media/events/past/gallipoli-3.jpg";
import imgHayat from "../media/events/past/hayat.jpg";
import imgIndigenous from "../media/events/past/indigenous.jpg";
import imgIndigenous2 from "../media/events/past/indigenous-2.jpg";
import imgKyle from "../media/events/past/kyle.jpg";
import imgMahoney from "../media/events/past/mahoney.jpg";
import imgManufacturing from "../media/events/past/manufacturing.jpg";
import imgMediaStudents from "../media/events/past/media-students.jpg";
import imgMinImmigration from "../media/events/past/min-immigration.jpg";
import imgNaidoc from "../media/events/past/naidoc.jpg";
import imgNeil from "../media/events/past/Neil.jpg";
import imgNZFilm from "../media/events/past/nz-film.jpg";
import imgPanelGouna from "../media/events/past/panel-gouna.jpg";
import imgPromotingAus from "../media/events/past/promoting-aus.jpg";
import imgRehany from "../media/events/past/rehany.jpg";
import imgRoger from "../media/events/past/roger.jpg";
import imgSponsored from "../media/events/past/sponsored.jpg";
import imgTen from "../media/events/past/ten.jpg";
import imgTheatreClosing from "../media/events/past/theatre-closing.jpg";
import imgWarMem1 from "../media/events/past/war-mem-1.jpg";
import imgWarMem2 from "../media/events/past/war-mem-2.jpg";

const Events = () => {
  const events = [
    {
      date: "2020",
      description:
        "Collaboration between Australia and Egypt organised by Anzeg during Covid 19",
      images: [imgAusEgCollab],
    },
    {
      date: "2018",
      description:
        "Anzeg participating in Univeristy collaborations organised by Ministry & Canadian Business Council",
      images: [imgCanadianBusiness],
    },
    {
      date: "2018",
      description: "Cairo Univeristy events",
      images: [imgCU],
    },
    {
      date: "__",
      description:
        "Co Chair leading a group of Egyptian administration professionals at the manufacturing plant in Cairo",
      images: [imgManufacturing],
    },
    {
      date: "__",
      description:
        "Egyptian media reporting on ANZEG's cultural exchange programs with Australia",
      images: [imgEgyMedia],
    },
    {
      date: "2021",
      description:
        "The Australian Film & Television Academy workshop with C. E. O John Orscik",
      images: [imgAusFilm],
    },
    { 
      date: "2018",
      description: "Egyptian media reporting on Australian artists coming to Egypt in cultural exchange program with Egyptian Ministry of Culture",
      images: [imgEgMediaAus]
    },
    { 
      date: "2018",
      description: "Egyptian media reporting on indigenous Australian art in Egypt hosted by Co Chair and Australian Embassy",
      images: [imgEgMediaInd]
    },    { 
      date: "2017",
      description: "Media students with Co Chair",
      images: [imgMediaStudents]
    },    { 
      date: "2018",
      description: "Co Chair with former Australian Ambassador to Egypt Neil Hawkins and current Australian Ambassador to Columbia Anna Chrisp",
      images: [imgNeil]
    },    { 
      date: "2018",
      description: "Initiation of Australian Cultural program in Cairo at the famous el Rehany Theatre",
      images: [imgRehany]
    },    { 
      date: "__",
      description: "Sponsored by the AFL & The Australian Embassy in Cairo",
      images: [imgSponsored]
    },
    { 
      date: "2018",
      description: "Australian Football program in Egypt. Supported by the AFL and the Australian Embassy in Cairo",
      images: [imgAFL1,imgAFL2,imgAFL3,imgAFL4,imgAFL5,imgAFL6,imgAFL7]
    },
    { 
      date: "2024",
      description: "Australia Day celeebrations 2024 at the Australian Embassy in Cairo with Australian Ambassador Dr Axel Wabenhorst",
      images: [imgAusDay]
    },
    { 
      date: "",
      description: "",
      images: []
    },
    {
      date: "2018",
      description:
        "Anzeg participating in organising Australian artsists at Cairo Univeristy Auditorium",
      images: [imgAusArtAuditorium],
    },
    {
      date: "2018",
      description: "Attending the festival of Drama & Cinema",
      images: [imgAttending],
    },
    {
      date: "2021",
      description:
        "Co Chair directing the Cairo Jazz Festival's video production",
      images: [imgCairoJazz],
    },
    {
      date: "2021",
      description: "Cairo Jazz Festival",
      images: [imgCairoJazzFest],
    },
    {
      date: "2022",
      description:
        "Co Chair farewelling the former Australian Ambassador Glenn Miles in Cairo",
      images: [imgFarewellAus],
    },
    {
      date: "2015",
      description:
        "Leadership & communications courses with Amoun Pharma Egypt",
      images: [imgAmoun],
    },
    {
      date: "2018",
      description:
        "Naidoc week with Australian Embassy & Australia Council Chair lead by ANZEG's Co Chair",
      images: [imgNaidoc],
    },
    {
      date: "2018",
      description:
        "ANZEG promoting and bringing over Australian artists to Egypt at the University Theatre Festival",
      images: [imgPromotingAus],
    },
    {
      date: "2018",
      description:
        "ANZEG helping Australian artists join the festival in Egypt",
      images: [imgAusArtFest],
    },
    {
      date: "2018",
      description:
        "Australian artist Mahoney Keily in Egypt to facilitate workshops to students",
      images: [imgMahoney],
    },
    {
      date: "2022",
      description:
        "Co Chair aided in connecting Egyptian artist Hayat Selim with practitioners in Canberra Australia to give a lecture at the ANU",
      images: [imgHayat],
    },
    {
      date: "__",
      description:
        "Co Chair welcoming the current Australian Ambassador Dr Axel Wabenhorst to Cairo",
      images: [imgCurrentAusAmb],
    },
    {
      date: "__",
      description:
        "Egyptian audience enjoying cultural events produced by Anzeg",
      images: [imgAudience],
    },
    {
      date: "2023",
      description:
        "ANZEG helping emerging talent in Cairo with production assistance",
      images: [imgEmergingTalent],
    },
    {
      date: "2024",
      description:
        "Co Chair commemorating the ANZAC's at the War Memorial in Cairo",
      images: [imgWarMem1, imgWarMem2],
    },
    {
      date: "2024",
      description:
        "Gallipoli play performed in Melbourne Australia as part of ANZEG's cultural program",
      images: [imgGallipoli, imgGallipoli2],
    },
    {
      date: "2024",
      description:
        "Author & historian Kyle Anderson visits Egypt to discuss his new book on Egypt's involvement in WW1",
      images: [imgKyle],
    },
    {
      date: "2024",
      description:
        "Cultural work between Australia and Egypt with legendary Australian actor Roger Ward and the crew of the Gallipoli play",
      images: [imgRoger, imgGallipoli3],
    },
    {
      date: "2018",
      description:
        "Production of Basira Information's company on the importance of transparency in Egypt directed by Co Chair",
      images: [imgBasira, imgBasira2],
    },
    {
      date: "2019",
      description:
        "Extra Curricular activities between Egypt and Australia in Cairo",
      images: [imgExtraCur],
    },
    {
      date: "2019",
      description:
        "Co Chair attending panel discussions at El Gouna Film Festival",
      images: [imgPanelGouna],
    },
    {
      date: "2019",
      description:
        "Co Chair organised New Zealand film Maker Jan Sandle to attend the British University Film Festival as panel member",
      images: [imgNZFilm],
    },
    {
      date: "2023",
      description:
        "Egyptian artist in Australia talking to the Australian Broadcasting Corporation as part of her trip and work with Theatre projects Canberra",
      images: [imgEgArtAus],
    },
    {
      date: "2018",
      description: "Closing ceremony of Theatre Festival",
      images: [imgTheatreClosing],
    },
    {
      date: "2016",
      description:
        "Australian Egyptian art and theatre collaboration directed by Co Chair",
      images: [imgAusEgTheatre],
    },
    {
      date: "2016",
      description:
        "Co Chair at the 57357 Hospital for Children with Cancer in Cairo donating his time with vocal training for the staff",
      images: [img57357],
    },
    {
      date: "2018",
      description:
        "Indigenous artist and Chair of Australia Council Lee Ann Buckskin in talks with Co Chair and Australian Embassy",
      images: [imgIndigenous, imgIndigenous2],
    },
    {
      date: "2020",
      description:
        "Co Chair discussing with Ten Media Egypt possible English media programs",
      images: [imgTen],
    },
    {
      date: "2019",
      description:
        "Visit from the Minister of Immigration who was involved in a business delegation to Australia",
      images: [imgMinImmigration],
    },
    {
      date: "2019",
      description:
        "Co Chair with Dean and Administration staff from Edith Cowen University Perth Australia discussing the first Australian university in Egypt",
      images: [imgEdith],
    },
  ];

  return (
    <>
      <NavBar />
      <div className="past-events-page">
        <div className="hero">
          <div className="hero-content fade-up">
            <h1>Past Events</h1>
            <p className="caveat">
              Empowering Local Businesses Through Advocacy, Networking, and
              Resources
            </p>
          </div>
        </div>
        <div className="events-header fade-up">
          <h1>Past Events</h1>
          <p>
            Relive some of our most memorable moments. Explore the highlights
            from past events that celebrated community, culture, and
            collaboration.
          </p>
        </div>

        <div className="event-list fade-up">
          {events.map((event, index) => (
            <div key={index} className="event-card">
              <div className="event-images">
                <img
                  key={index}
                  src={event.images[0]}
                  alt={`Event ${index + 1}`}
                />
              </div>
              <div className="event-info">
                <p>{event.description}</p>
                <p>
                  <strong>Date:</strong> {event.date}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Events;
