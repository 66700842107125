import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./NavBar.scss";

function NavBar() {
  const [menuOpen, setMenu] = useState(false);

  const handleMenuClick = () => {
    setMenu(!menuOpen);
  };

  return (
    <nav className="navBar">
      <div className="logo">
        <Link to="/">
          <p>
            ANZEG <strong className="smaller">institute</strong>
          </p>
        </Link>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/membership">Membership</NavLink>
        </li>
        <li>
          <NavLink to="/past-events">Past Events</NavLink>
        </li>
        <li>
          <NavLink to="/events">Future Events</NavLink>
        </li>
        <li>
          <NavLink to="/cultural-exchange">Cultural Exchange</NavLink>
        </li>
        <li>
          <NavLink to="/international-education">International Education</NavLink>
        </li>
        <li>
          <NavLink to="/trade-missions-and-delegations">
            Trade Missions & Delegations
          </NavLink>
        </li>
      </ul>
      <div className="menu" onClick={handleMenuClick}>
        <i className={menuOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
      </div>
    </nav>
  );
}

export default NavBar;
