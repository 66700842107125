import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./International.scss";

const International = () => {
  return (
    <div className="int">
      <NavBar />
      <div className="content">
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-overlay">
            <div className="hero-content fade-up">
              <h1 className="hero-heading">International Education</h1>
              <p className="hero-text">
                Industry-specific courses, webinars, and more
              </p>
              <button className="cta-button">Join Us Today</button>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section className="benefits">
          <div className="inner-content">
            <h2 className="section-heading fade-up">International Education</h2>
            <p className="fade-up">
              ANZEG offers educational courses and webinars that members can
              attend. ANZEG Hosts specialized courses, ranging from
              industry-specific insights to broader topics like leadership,
              intercultural dialogue, digital marketing, fintech, agriculture,
              insurance, banking and recruitment .ANZEG also offers mental
              well-being in the workplace and that is why our chamber is seen as
              a thought leader. Collaborations with recognized industry experts,
              academic institutions and influencers further elevate our appeal.
              Professional development programs are also a staple of our
              organization in different areas of industry and culture & art.
            </p>
          </div>
        </section>
        <section className="about">
          <div className="about-container fade-up">
            <div className="inner-content">
              <h2>About Us</h2>
              <p>
                ANZEG has access to financial support mechanisms facilitated by
                governmental and non-governmental bodies. Depending on the
                region and the chamber’s objectives, it might be eligible for
                local government grants or funding programs designed to support
                individuals & communities..
              </p>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2 className="section-heading fade-up">What Our Members Say</h2>
          <div className="testimonials-container fade-up">
            <div className="testimonial-item">
              <p className="testimonial-text">
                "ANZEG has transformed the way we collaborate with other
                businesses. It's a game-changer."
              </p>
              <p className="testimonial-author">- John Doe</p>
            </div>
            <div className="testimonial-item">
              <p className="testimonial-text">
                "The cultural sponsorships opened up incredible opportunities
                for our organization."
              </p>
              <p className="testimonial-author">- Jane Smith</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact">
          <div className="inner-content">
            <h2 className="section-heading fade-up">Get in Touch</h2>
            <p className="fade-up">
              We'd love to hear from you! Reach out to us for more information
              or collaboration opportunities.
            </p>
          </div>

          {/* <button className="contact-button fade-up">Contact Us</button> */}
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default International;
