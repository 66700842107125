import React from "react";
import "./Events.scss";
import NavBar from "./NavBar";
import Footer from "./Footer";
import oud from "../media/oud.jpg";
import australian from "../media/events/upcoming/austrailian-embassy-book.jpg";
import kyle from "../media/events/upcoming/kyle.jpg";


const Events = () => {
  const dummyEvents = [
    {
      id: 1,
      title: "Australian Embassy Book Donations",
      date: "TBA",
      time: "TBA",
      description:
        "With the assistence of ANZEG the Australian Embassy in Cairo has been generous enough to donate some Australian books about art, culture and science in Australia to the fabulous library at the American univeristy in Cairo",
      image:
        australian,
    },
    {
      id: 2,
      title: "Joseph Tawadros",
      date: "December 21, 2024",
      time: "TBA",
      description:
        "Anzeg is proud to Present to you Australian Oud virtuoso Joseph Tawadros along with the Alexandrian String Orchestra and Cuban conductor Veronica Bievs a night of Oud. 21st December in the Grand Hall Alexandria library",
      image:
        oud,
    },
    {
      id: 3,
      title: "Recruitment of Australians and Egyptians in the Great War : The Referendem & The ELC",
      date: "April 25, 2025",
      time: "TBA",
      description:
        "conversation with Kyle Anderson at the Egyptian Embassy in Canberra",
      image:
        kyle,
    },
  ];

  return (
    <>
      <NavBar></NavBar>
      <div className="events-page">
        <div className="hero">
          <div className="hero-conten fade-up">
            <h1>Events</h1>
            <p className="caveat">
              Empowering Local Businesses Through Advocacy, Networking, and
              Resources
            </p>
          </div>
        </div>
        <div className="events-header">
          <div className="fade-up">
          <h1>Upcoming Events</h1>
          <p>
            Join us for exciting events that foster networking, learning, and
            community growth. Whether you’re looking to expand your business
            connections or learn new skills, ANZEG’s events are the perfect
            opportunity.
          </p>
          </div>
        </div>

        <div className="event-list fade-up">
          {dummyEvents.map((event) => (
            <div key={event.id} className="event-card">
              <img src={event.image}></img>
              <div className="event-info">
                <h2>{event.title}</h2>
                <p>
                  <strong>Date:</strong> {event.date}
                </p>
                <p>
                  <strong>Time:</strong> {event.time}
                </p>
                <p>{event.description}</p>
                <button className="register-button">Register</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Events;
