// src/App.js
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Membership from "./pages/Membership";
import Events from "./pages/Events";
import Culture from "./pages/Culture";
import International from "./pages/International";
import Trade from "./pages/Trade";
import Test from "./pages/Test";
import PastEvents from "./pages/PastEvents";




const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/membership", element: <Membership /> },
  { path: "/events", element: <Events /> },
  { path: "/cultural-exchange", element: <Culture /> },
  { path: "/international-education", element: <International /> },
  { path: "/trade-missions-and-delegations", element: <Trade /> },
  { path: "/past-events", element: <PastEvents /> },
  { path: "/test", element: <Test /> },



]);

const App = () => {
  return (
    <>

      <RouterProvider router={router}></RouterProvider>

    </>
  );
};

export default App;
