import React from "react";
import "./Test.scss";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

import background from "../media/background.jpg";
import bg2 from "../media/bg2.jpg";
import webinar from "../media/webinarH.jpg";
import scene from "../media/scene.jpg";

import { useNavigate, Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleNavigate = (to) => {
    console.log("clicked");
    navigate(to);
  };

  return (
    <>
    <div className='test'>
      <NavBar />
      <div className="homepage">
        <div className="hero">
          <div className="img-bg">
            <img src={background} className="bg" alt="Hero Background" />
            <div className="hero-content">
              <h1>Welcome to ANZEG Institute Chamber of Commerce & Cultural Exchange</h1>
              <p className="caveat">
                Empowering International Businesses Through Advocacy, Networking, and Resources
              </p>
              <div className="hero-buttons">
                <Link to="/membership" className="btn primary-btn">
                  Join Us Today
                </Link>
                <Link to="/events" className="btn primary-btn">
                  View Upcoming Events
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="membership-benefits">
          <img className="bg" src={bg2} alt="Membership Benefits Background" />
          <div className="content">
            <h2>What We Offer</h2>
            <ul>
              <li><strong>Networking:</strong> Connect with businesses, clients, and partners.</li>
              <li><strong>Resources:</strong> Access industry reports, leadership programs, and more.</li>
              <li><strong>Advocacy:</strong> We advocate for policies that support local businesses.</li>
              <li><strong>Exposure:</strong> Grow your brand through our marketing platforms.</li>
            </ul>
            <button className="cta-button" onClick={() => handleNavigate("/membership")}>
              Become a Member
            </button>
          </div>
        </section>

        <section className="abs-bg">
          <img src={scene} className="bg" alt="Why ANZEG Background" />
          <div className="content">
            <h2>Why ANZEG Institute?</h2>
            <p>
              Membership with ANZEG Institute is more than just being part of a
              business network—it's an investment in your business's future.
              Membership fees help fund critical services like industry
              research, leadership development programs, networking events, and
              advocacy campaigns.
            </p>
          </div>
        </section>

        <section className="social-media">
          <h2>Follow Us</h2>
          <div className="social-links">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </div>
        </section>

      </div>
    </div>
      <Footer />
</>
  );
};

export default Home;
