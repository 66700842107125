import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import "./Culture.scss";

const CulturalExchangePage = () => {
  return (
    <div className="cult">
      <NavBar />
      <div className="content">
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-overlay">
            <div className="hero-content fade-up">
              <h1 className="hero-heading">
                Discover the Power of Cultural Exchange
              </h1>
              <p className="hero-text">
                Bridging communities, fostering growth, and creating synergies
                through culture.
              </p>
              <button className="cta-button">Join Us Today</button>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section className="benefits">
          <div className="inner-content">
            <h2 className="section-heading fade-up">Cultural Exchange</h2>
            <p className="fade-up">
              Joining ANZEG is more than just being part of a business group;
              it’s entering into a community of growth-minded entities committed
              to mutual success. Through virtual networking events, mentorship
              programs, happy hours, or professional development workshops,
              these platforms enable businesses and individuals within the
              community to build and strengthen relationships with one another.
              Engaging in cultural activities can open doors for collaborations,
              partnerships, and even client referrals.Members can benefit from
              cultural sponsorship opportunities, referral programs, new member
              spotlights, and access to new advertising channels. All of these
              avenues can boost activities for a variety of cultural
              professionals including filmmakers, theatre practitioners,
              musicians, artists, writers and producers' visibility and growth
              potential. Such collaborations not only help the member but also
              strengthen community ties and promote cultural diversity,
              tolerance and bring cultural practitioners together from
              different nations and help build bridges of understanding and
              growth.
            </p>
          </div>
        </section>
        <section className="about">
          <div className="about-container fade-up">
            <div className="inner-content">
              <h2>About Us</h2>
              <p>
                ANZEG is dedicated to creating meaningful cultural exchanges
                that connect people across borders. We believe in the
                transformative power of shared experiences and collaborative
                growth.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits">
          <h2 className="section-heading fade-up">Member Benefits</h2>
          <ul className="inner-content fade-up">
            <li>
              Networking through virtual events, mentorship programs, and
              professional workshops
            </li>
            <li>Cultural sponsorship opportunities and referral programs</li>
            <li>
              New member spotlights and advertising channels for cultural
              professionals
            </li>
          </ul>
        </section>

        {/* Collaborations Section */}
        <section className="collaborations">
          <div className="inner-content">
            <h2 className="section-heading fade-up">
              Building Cultural Synergies
            </h2>
            <p className="fade-up">
              ANZEG collaborates with local and international organizations to
              foster mutual benefits. Our members engage in joint ventures and
              co-hosted events with businesses and corporations.
            </p>
            <p className="fade-up">Opportunities include:</p>

            <ul className="opportunities-list fade-up">
              <li>Shared marketing efforts</li>
              <li>Event sponsorships and partnerships</li>
              <li>
                Community programs promoting cultural diversity and growth
              </li>
            </ul>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2 className="section-heading fade-up">What Our Members Say</h2>
          <div className="testimonials-container fade-up">
            <div className="testimonial-item">
              <p className="testimonial-text">
                "ANZEG has transformed the way we collaborate with other
                businesses. It's a game-changer."
              </p>
              <p className="testimonial-author">- John Doe</p>
            </div>
            <div className="testimonial-item">
              <p className="testimonial-text">
                "The cultural sponsorships opened up incredible opportunities
                for our organization."
              </p>
              <p className="testimonial-author">- Jane Smith</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact">
          <div className="inner-content">
            <h2 className="section-heading fade-up">Get in Touch</h2>
            <p className="fade-up">
              We'd love to hear from you! Reach out to us for more information
              or collaboration opportunities.
            </p>
          </div>

          {/* <button className="contact-button fade-up">Contact Us</button> */}
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default CulturalExchangePage;
