import React from "react";
import "./Home.scss";
import NavBar from "./NavBar";
import Footer from "./Footer";
import scene from "../media/scene.jpg";
import bg2 from "../media/bg2.jpg";
import webinar from "../media/webinarH.jpg";

import background from "../media/background.jpg";

import { useNavigate, Link } from "react-router-dom";

const Home = () => {
  const naviagte = useNavigate();
  const handleNavigate = (to) => {
    console.log("clicked");
    naviagte(to);
  };
  return (
    <>
      <NavBar></NavBar>
      <div className="homepage">
        <div className="hero">
          <div className="content fade-up">
            <h1>
              Welcome to ANZEG institute Chamber of Commerce & Cultural Exchange
            </h1>
            <p>
              Empowering International Businesses Through Advocacy, Networking,
              and Resources
            </p>
          </div>
        </div>
        <section className="about">
          <div className="content fade-up">
            <h1>About ANZEG institute</h1>
            <p>
              The ANZEG institute Chamber of Commerce is dedicated to fostering
              the growth and success of businesses within the local community
              and beyond. Our mission is to provide essential resources,
              networking opportunities, and advocacy to help businesses thrive.
            </p>
          </div>
        </section>
        <section className="membership-benefits">
          <div className="content fade-up">
            <h1>What we offer</h1>
            <ul>
              <li>
                <strong>Networking:</strong> Connect with businesses, clients,
                and partners.
              </li>
              <li>
                <strong>Resources:</strong> Access industry reports, leadership
                programs, and more.
              </li>
              <li>
                <strong>Advocacy:</strong> We advocate for policies that support
                local businesses.
              </li>
              <li>
                <strong>Exposure:</strong> Grow your brand through our marketing
                platforms.
              </li>
            </ul>
            <Link to='/membership'>Join Now</Link>
          </div>
        </section>

        <section className="about">
          <div className="content fade-up">
            <h1>Upcoming Events</h1>
            <p>
            Explore our upcoming networking events, webinars, and workshops
            designed to help your business grow.
            </p>
          <Link to='/events'>To Events</Link>

          </div>
        </section>

        <section className="membership-benefits why">
          <div className="content fade-up">
            <h1>Why ANZEG institute ?</h1>
            <p>
              Membership with ANZEG institute is more than just being part of a
              business network—it's an investment in your business's future.
              Membership fees help fund critical services like industry
              research, leadership development programs, networking events, and
              advocacy campaigns. These initiatives are designed to empower
              members by providing them with the tools and connections they need
              to succeed in a competitive marketplace. By joining ANZEG
              institute, you align your business with a trusted organization
              that advocates for your interests, connects you with
              opportunities, and offers a wealth of resources tailored to help
              you grow.
            </p>

            <Link to='/membership'>Join Now</Link>
          </div>
        </section>

        <section className="about">
          <div className="content fade-up">
            <h1>Collaboration & Sponsorships</h1>
            <p>
            ANZEG institute partners with businesses and organizations for event
            sponsorships and joint ventures. Collaborate with us to enhance
            visibility, expand your reach, and engage with our diverse network.
          </p>
          <p>
            Our sponsorship opportunities allow your brand to stand out during
            high-profile events, such as our fundraising galas, networking
            mixers, and business workshops.
          </p>
          </div>
        </section>

        <section className="membership-benefits funding">
          <div className="content fade-up">
            <h1>Research Grants & Government Funding</h1>
            <p>
            ANZEG institute supports businesses by providing access to
            government grants and financial support programs. We connect members
            with the resources necessary to ensure their businesses thrive and
            remain flexible.
          </p>
          <p>
            Discover available research grants and take advantage of funding
            opportunities that help ensure your business’s longevity in today’s
            competitive landscape.
          </p>

            <Link to='/membership'>Join Now</Link>
          </div>
        </section>

      </div>


      <Footer />
    </>
  );
};

export default Home;
