import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import {Link} from 'react-router-dom'
import "./Trade.scss";

const Trade = () => {
  return (
    <div className="trade">
      <NavBar />
      <div className="content">
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-overlay">
            <div className="hero-content fade-up">
              <h1 className="hero-heading">
                Discover the Power of Trade Missions
              </h1>
              <p className="hero-text">
                Fostering success, building connections, and creating synergies
                through trade.
              </p>
              <button className="cta-button"><Link to='https://www.facebook.com/share/19PzDk2nEE/'></Link>Join Us Today</button>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section className="benefits">
          <div className="inner-content">
            <h2 className="section-heading fade-up">
              Trade Missions & Delegations
            </h2>
            <p className="fade-up">
              ANZEG plays a pivotal role in fostering the success of businesses
              in their local communities. Our influence extends beyond
              networking and resources; we serve as the backbone for local and
              international economic development and advocacy.
            </p>
            <p className="fade-up">
              Membership fees ensure that ANZEG can continue our vital work.
              Engaging in our trade missions can open doors for collaborations,
              partnerships, and international trade.
            </p>
          </div>
        </section>

        <section className="about">
          <div className="about-container fade-up">
            <div className="inner-content">
              <h2>How Trade Missions Work?</h2>
              <p>
                ANZEG provides access to a wide array of resources, supported by
                membership fees, including a directory, discounts, industry
                research, and leadership development programs. We also offer
                online tools and webinars to help businesses adapt to the
                digital age. These resources empower local businesses to stay
                competitive, with additional opportunities for international
                trade visits.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits">
          <h2 className="section-heading fade-up">
            Dynamic Economic Environment
          </h2>
          <ul className="inner-content fade-up">
            <li>
              •dynamic and rapidly growing economic environment for exploration
              and collaboration require official invitations from the Ministries
              of Foreign Affairs to assist in Business Forums, aimed at
              attracting prominent corporations with ANZEGS counterparts to
              foster stronger economic ties.
            </li>
            <li>
              Missions are usually in cooperation with the Embassies and other
              Government entities.
            </li>
            <li>
              Events featured on these business trips include presentations and
              speeches from both nations with a focus on bilateral trade.
            </li>
            <li>
              •Discussions on these delegations mostly agriculture, education,
              health, tourism, and finance. The meetings provide information for
              foreign investors from the Public-Private sectors.
            </li>
          </ul>
        </section>

        {/* Collaborations Section */}
        <section className="about">
          <div className="about-container fade-up">
            <div className="inner-content">
              <h2>Topics of Discussion</h2>
              <p>
                These missions often feature discussions on agriculture,
                education, health, tourism, and finance. Meetings provide
                invaluable insights for foreign investors from the
                Public-Private sectors.
              </p>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2 className="section-heading fade-up">What Our Members Say</h2>
          <div className="testimonials-container fade-up">
            <div className="testimonial-item">
              <p className="testimonial-text">
                "ANZEG's trade missions opened up incredible opportunities for
                international partnerships."
              </p>
              <p className="testimonial-author">- John Doe</p>
            </div>
            <div className="testimonial-item">
              <p className="testimonial-text">
                "These missions have been a game-changer in creating economic
                ties across borders."
              </p>
              <p className="testimonial-author">- Jane Smith</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="contact">
          <div className="inner-content">
            <h2 className="section-heading fade-up">Get in Touch</h2>
            <p className="fade-up">
              Interested in joining our next trade mission? Contact us for more
              details and collaboration opportunities.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Trade;
