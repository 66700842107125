import React from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";
import './Membership.scss';
import conference from '../media/conference.jpg';

const Membership = () => {
  return (
    <>
      <NavBar></NavBar>
      <div className="membership-page">
        <header className="membership-header">
          <img className='bg' src={conference}></img>
          <div className='content fade-up'>
          <h1>ANZEG Membership</h1>
          <p>
            The ANZEG institute Chamber of Commerce offers a gateway to valuable connections, business growth, and industry-leading insights 
            through a strong, dynamic community of professionals across Egypt.
          </p>
          <p>
            Whether you're an entrepreneur, SME, or a large corporate, ANZEG institute provides you with the tools and platform to enhance 
            your business and expand your influence within your industry.
          </p>
          </div>

        </header>

        {/* Membership Plans */}
        <div className="membership-plans">
          <div className="plan corporate-plan fade-up">
            <h2>Corporate Membership</h2>
            <p className="price">$7,500 +GST</p>
            <ul>
              <li>Membership for 30+ members at a regional and international level</li>
              <li>Exclusive invitations to high-profile events and summits</li>
              <li>Brand visibility across our digital channels and publications</li>
              <li>Access to executive business forums and leadership programs</li>
              <li>Special pricing for international business delegations and seminars</li>
            </ul>
            <button className="join-btn">Become a member</button>
          </div>

          <div className="plan sme-plan fade-up">
            <h2>Annual Membership</h2>
            <p className="price">$3,500 +GST</p>
            <ul>
              <li>Membership for up to 10+ members</li>
              <li>Invitations to tailored networking events</li>
              <li>Brand promotion across select events and publications</li>
              <li>Access to business forums and workshops</li>
              <li>Discounts on all ANZEG institute events and educational webinars</li>
            </ul>
            <button className="join-btn">Become a member</button>
          </div>

          <div className="plan individual-plan fade-up">
            <h2>Quarterly Membership</h2>
            <p className="price">$600 +GST</p>
            <ul>
              <li>Membership for up to 5 members</li>
              <li>Exclusive invitations to member-only events</li>
              <li>Access to business networking forums</li>
              <li>Discounts on ANZEG institute events and seminars</li>
            </ul>
            <button className="join-btn">Become a member</button>
          </div>
        </div>

        {/* CTA Banner */}
        <div className="cta-banner">
          <div className='fade-up'>
          <h2>Ready to grow your business with ANZEG?</h2>
          <p>Join today and unlock access to exclusive resources, networking opportunities, and advocacy tailored to help your business thrive.</p>
          </div>
        </div>

        

        {/* FAQs */}
        <div className="faqs">
        <div className='fade-up'>

          <h2>Frequently Asked Questions</h2>
          <div className="faq-item">
            <h3>What are the membership fees used for?</h3>
            <p>
              ANZEG membership fees are used to support operational costs, fund research, advocacy campaigns, and networking events. They enable the chamber to provide valuable resources to our members.
            </p>
          </div>
          <div className="faq-item">
            <h3>What kind of events can I expect as a member?</h3>
            <p>
              Members can attend a wide range of events, including business forums, leadership development workshops, and international business delegations. Exclusive members also get access to high-profile events and summits.
            </p>
          </div>
          <div className="faq-item">
            <h3>How do I join or renew my membership?</h3>
            <p>
              You can join or renew your membership by filling out the online membership form on our website. We offer flexible payment options and membership plans tailored to suit businesses of all sizes.
            </p>
          </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Membership;
